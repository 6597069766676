import { Pipe, PipeTransform } from '@angular/core';
import { ROUTES_PARTS } from '@common/consts';

function getTypeWithId(nomenclature) {
  if (nomenclature.familyId) {
    return {
      type: 'family',
      id: nomenclature.familyId,
    };
  } else if (nomenclature.functionalTypeId) {
    return {
      type: 'functional-type',
      id: nomenclature.functionalTypeId,
    };
  } else if (nomenclature.materialId) {
    return {
      type: 'material',
      id: nomenclature.materialId,
    };
  }
}

@Pipe({
  name: 'nomenclatureUrl',
})
export class NomenclatureUrlPipe implements PipeTransform {
  transform(value: any): string {
    const typeWithId = getTypeWithId(value.nomenclature);
    const link =
      ROUTES_PARTS.BINDINGS +
      '/' +
      'Internal' +
      '/' +
      typeWithId.type +
      '/' +
      typeWithId.id +
      ';' +
      new URLSearchParams({ group: value.nomenclature.groupNumber, binding: value.bindingId })
        .toString()
        .replace('&', ';');
    return link;
  }
}
