import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DateAdapter, PikInputModule, PikTooltipModule } from '@pik-ui/ng-components';
import { MatTableModule } from '@angular/material/table';

import { DateFormatPipe } from '@shared/pipes/date-format/date-format.pipe';
import { LinkTransformDirective, OpenTabDirective, YandexTargetDirective } from '@shared/directives';
import { MarkdownModule } from 'app/shared/components/markdown';
import { SafeUrlPipe } from '@shared/pipes/safe-url/safe-url.pipe';
import { HasDisciplinesDirective } from '@core/account/directives/has-disciplines.directive';
import { AttributesSortPipe } from '@shared/components/attributes-view/attributes-view-sort.pipe';

import { PikComponentsModule } from './modules';
import { SafeHtmlPipe } from './pipes';
import {
  AttachmentsComponent,
  AttributesControlComponent,
  AttributesFilterControlComponent,
  AttributesLinkComponent,
  AttributesViewComponent,
  AttributeTypeTagComponent,
  BindingsLinkComponent,
  BindingTagComponent,
  CategoriesControlComponent,
  CategoryTagComponent,
  ChatComponent,
  ChatMessageComponent,
  ChatMessageFormComponent,
  CheckIconComponent,
  DataTypeControlComponent,
  DataTypesControlComponent,
  DataTypeLinkComponent,
  DisciplineControlComponent,
  DisciplinesControlComponent,
  DisciplinesTagsComponent,
  DisciplineTagComponent,
  ExpandableSectionComponent,
  FamiliesControlComponent,
  FamiliesLinkComponent,
  FamiliesTagComponent,
  MaterialCountTagComponent,
  FamilyControlComponent,
  FamilyFtControlComponent,
  FamilySymbolTagComponent,
  FamilyTagComponent,
  FamilyTypeTagComponent,
  FileItemComponent,
  FmInfoTooltipModule,
  FmModalGoogleSheetComponent,
  FoldersControlComponent,
  FunctionalTypeControlComponent,
  FunctionalTypesControlComponent,
  FunctionalTypeParameterControlComponent,
  FunctionalTypesLinkComponent,
  FunctionalTypeTagComponent,
  IconButtonComponent,
  IdValueListControlComponent,
  ImageLoaderComponent,
  ImageLoaderControlComponent,
  LanguageSwitcherComponent,
  LoadLogLinkComponent,
  MaterialControlComponent,
  MaterialParametersLinkComponent,
  MaterialsControlComponent,
  MaterialsLinkComponent,
  MaterialTagComponent,
  MultiSelectModule,
  NomenclatureKindControlComponent,
  NomenclatureTypesControlComponent,
  NomenclatureTypeTagComponent,
  OptionsListComponent,
  ParameterControlComponent,
  ParameterLinkComponent,
  ParametersControlComponent,
  ParametersViewModule,
  ParameterValueOwnerControlComponent,
  PermissionTagsComponent,
  ProjectsTagComponent,
  RequestLinkComponent,
  RequestsControlComponent,
  RequestTagComponent,
  RolesControlComponent,
  RoleTypeTagComponent,
  SelectModule,
  SheetButtonsComponent,
  StandardsControlComponent,
  StandardTagsComponent,
  StatusControlComponent,
  StatusesControlComponent,
  StringListControlComponent,
  SymbolsControlComponent,
  TablePaginatorModule,
  TableActionButtonComponent,
  TagsItemComponent,
  UniqueParameterControlComponent,
  UniqueParameterLinkComponent,
  UniqueParametersControlComponent,
  UnitTypeControlComponent,
  UnitTypesControlComponent,
  UnitTypeLinkComponent,
  UserControlComponent,
  UserLinkComponent,
  UsersControlComponent,
  ViewDrawerComponent,
  StandardControlComponent,
  RolesTagsComponent,
  BindingsLinkCardComponent,
  TabsRouterComponent,
} from './components';

import {
  FmAppTagComponent,
  FmBackButtonComponent,
  FmBlockTitleComponent,
  FmCopyButtonComponent,
  FmDropdownBottomBarComponent,
  FmEditableOptionComponent,
  FmEditRowFormComponent,
  FmHintComponent,
  FmModalDialogComponent,
  FmModalMessageComponent,
  FmStatusTagComponent,
} from './components/fm-ui';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ExpandableBlockComponent } from './components/expandable-block/expandable-block.component';
import { ConditionLinkModule } from '@shared/components/condition-link';
import { AttributesListComponent } from '@shared/components/controls/attributes-control';
import { CheckEmptyComponent } from '@shared/components/icons/check-empty';
import { ListTagsComponent } from '@shared/components/tags/list-tags/list-tags.component';
import { DrawerCommonFooterComponent } from './components/drawer-common-footer/drawer-common-footer.component';
import { UserPhotoComponent } from '@features/users/views/user/components/user-photo/user-photo.component';
import { AlarmMessageComponent } from './components/alarms/alarm-message/alarm-message.component';
import { HistoryComponent } from './components/history/history.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { BooleanTagComponent } from './components/boolean-tag/boolean-tag.component';
import { NomenclatureUrlPipe } from '@features/elements-check/nomenclature-url.pipe';
import { HistoryDatePipe } from './components/history/pipes/history-date.pipe';

const FM_UI = [
  FmBlockTitleComponent,
  MaterialsLinkComponent,
  UniqueParametersControlComponent,
  FmBackButtonComponent,
  FmCopyButtonComponent,
  MaterialParametersLinkComponent,
  FunctionalTypesLinkComponent,
  FmModalMessageComponent,
  FmStatusTagComponent,
  FmEditRowFormComponent,
  FmModalDialogComponent,
  FmModalGoogleSheetComponent,
  FmAppTagComponent,
  FmEditableOptionComponent,
  NomenclatureTypesControlComponent,
  FmDropdownBottomBarComponent,
  NomenclatureKindControlComponent,
  FmHintComponent,
  TagsItemComponent,
  IconButtonComponent,
  TableActionButtonComponent,
  FileItemComponent,
  AttachmentsComponent,
  StandardTagsComponent,
  DisciplinesTagsComponent,
  DisciplineTagComponent,
  IdValueListControlComponent,
  FunctionalTypeTagComponent,
  BindingTagComponent,
  RequestTagComponent,
  FamiliesControlComponent,
  FamilyControlComponent,
  FamilyTagComponent,
  FamilyTypeTagComponent,
  CategoriesControlComponent,
  ImageLoaderComponent,
  FoldersControlComponent,
  MaterialControlComponent,
  StatusControlComponent,
  StatusesControlComponent,
  MaterialsControlComponent,
  UnitTypeControlComponent,
  UnitTypesControlComponent,
  DataTypeControlComponent,
  DataTypesControlComponent,
  UniqueParameterControlComponent,
  ParameterControlComponent,
  ParametersControlComponent,
  MaterialTagComponent,
  RequestsControlComponent,
  RolesControlComponent,
  SymbolsControlComponent,
  AttributeTypeTagComponent,
  StandardsControlComponent,
  StandardControlComponent,
  FamilyFtControlComponent,
  AttributesFilterControlComponent,
  ParameterLinkComponent,
  DataTypeLinkComponent,
  DisciplineControlComponent,
  ExpandableBlockComponent,
  ParameterValueOwnerControlComponent,
  ProjectsTagComponent,
  ImageLoaderControlComponent,
  StringListControlComponent,
  NomenclatureTypeTagComponent,
  ViewDrawerComponent,
  CategoryTagComponent,
  FamiliesTagComponent,
  MaterialCountTagComponent,
  LanguageSwitcherComponent,
  UserLinkComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    PerfectScrollbarModule,
    RoleTypeTagComponent,
    ReactiveFormsModule,
    FormsModule,
    AngularSvgIconModule,
    PikComponentsModule,
    MarkdownModule,
    PikTooltipModule,
    PikInputModule,
    TablePaginatorModule,
    MatTableModule,
    FmInfoTooltipModule,
    ParametersViewModule,
    MultiSelectModule,
    SelectModule,
    ConditionLinkModule,
    BindingsLinkComponent,
    CheckIconComponent,
    CheckEmptyComponent,
    ListTagsComponent,
    UniqueParameterLinkComponent,
    UnitTypeLinkComponent,
    PermissionTagsComponent,
    FamiliesLinkComponent,
    RequestLinkComponent,
    LoadLogLinkComponent,
    AttributesLinkComponent,
    RolesTagsComponent,
    BindingsLinkCardComponent,
    TabsRouterComponent,
    MatListModule,
    MatIconModule,
  ],
  declarations: [
    NomenclatureUrlPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    UserPhotoComponent,
    AttributesSortPipe,
    DateFormatPipe,
    YandexTargetDirective,
    ChatComponent,
    ChatMessageComponent,
    ChatMessageFormComponent,
    AttributesControlComponent,
    DisciplinesControlComponent,
    FunctionalTypeControlComponent,
    FunctionalTypesControlComponent,
    FunctionalTypeParameterControlComponent,
    AttributesViewComponent,
    ...FM_UI,
    LinkTransformDirective,
    HasDisciplinesDirective,
    UsersControlComponent,
    UserControlComponent,
    OpenTabDirective,
    ExpandableSectionComponent,
    FunctionalTypeControlComponent,
    FamilySymbolTagComponent,
    OptionsListComponent,
    AttributesListComponent,
    SheetButtonsComponent,
    DrawerCommonFooterComponent,
    AlarmMessageComponent,
    HistoryComponent,
    BooleanTagComponent,
    HistoryDatePipe,
  ],
  exports: [
    // Modules
    RouterModule,
    UserPhotoComponent,
    AngularSvgIconModule,
    ParametersViewModule,
    PikComponentsModule,
    MarkdownModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    FmInfoTooltipModule,
    TablePaginatorModule,
    MatTableModule,
    TranslateModule,
    SelectModule,
    MultiSelectModule,
    ConditionLinkModule,
    // Declarations
    ...FM_UI,
    UsersControlComponent,
    UserControlComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    DateFormatPipe,
    NomenclatureUrlPipe,
    YandexTargetDirective,
    ChatComponent,
    ChatMessageComponent,
    ChatMessageFormComponent,
    AttributesControlComponent,
    AttributesViewComponent,
    DisciplinesControlComponent,
    ExpandableSectionComponent,
    LinkTransformDirective,
    FunctionalTypeControlComponent,
    FunctionalTypesControlComponent,
    FunctionalTypeParameterControlComponent,
    HasDisciplinesDirective,
    OpenTabDirective,
    CategoriesControlComponent,
    FamilySymbolTagComponent,
    OptionsListComponent,
    SheetButtonsComponent,
    DrawerCommonFooterComponent,
    AlarmMessageComponent,
    BooleanTagComponent,
  ],
})
export class SharedModule {
  constructor(private translate: TranslateService, private adapter: DateAdapter<Date>) {
    this.translate.onLangChange.subscribe(({ lang }: LangChangeEvent) => this.adapter.setLocale(lang));
  }
}
