import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  AttachmentDto,
  IdNameDto,
  RequestDto,
  RequestLightDto,
  RequestStateChangeDto,
  RequestUpdateDto,
} from '@common/dto';
import { RequestsFilterParams } from '@common/models/filters';
import { CrudService, ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';
import { getRequestCreateUpdateMode, getRequestTypesMode } from '@features/requests/views/request/utils/common';
import { ERequestCreateUpdate } from '@features/requests/views/request/consts';

@Injectable({ providedIn: 'root' })
export class RequestsApiService implements CrudService<RequestDto, RequestUpdateDto, RequestUpdateDto> {
  constructor(private http: HttpClient) {}

  public getList(filter: RequestsFilterParams) {
    let params = filter ? filter.toApiParams() : {};
    //фильтры по семействам и материалам распространяются и
    //на те которые были указаны при создании заявки и на те которые были указаны в решениях заявок
    if (params['includeRequestSolutionFamilyVersion']) {
      params = {
        ...params,
        requestSolutionFamilyVersionIds: params['familyIds'],
      };
    }

    if (params['includeRequestSolutionMaterial']) {
      params = {
        ...params,
        requestSolutionMaterialIds: params['materialIds'],
      };
    }
    delete params['includeRequestSolutionFamilyVersion'];
    delete params['includeRequestSolutionMaterial'];

    return this.http.post<ResultPart<RequestLightDto>>(`${ApiPaths.Requests}/filter`, params);
  }

  public getById(requestId: number) {
    return this.http.get<RequestDto>(`${ApiPaths.Requests}/${requestId}`).pipe(
      map((request) => {
        return {
          ...request,
          isCreateMode: getRequestCreateUpdateMode(request.requestTypeId) === ERequestCreateUpdate.create,
          mode: getRequestTypesMode(request.requestTypeId),
        };
      }),
    );
  }

  public create(createDto: Partial<RequestUpdateDto>) {
    return this.http.post<RequestDto>(ApiPaths.Requests, createDto).pipe(
      map((request) => {
        return {
          ...request,
          isCreateMode: getRequestCreateUpdateMode(request.requestTypeId) === ERequestCreateUpdate.create,
          mode: getRequestTypesMode(request.requestTypeId),
        };
      }),
    );
  }

  public update(updateDto: Partial<RequestUpdateDto>) {
    return this.http.put<RequestDto>(`${ApiPaths.Requests}/${updateDto.id}`, updateDto).pipe(
      map((request) => {
        return {
          ...request,
          isCreateMode: getRequestCreateUpdateMode(request.requestTypeId) === ERequestCreateUpdate.create,
          mode: getRequestTypesMode(request.requestTypeId),
        };
      }),
    );
  }

  public sendRequestAction(requestId: number, patchDto: Partial<RequestStateChangeDto>) {
    return this.http.post<RequestDto>(`${ApiPaths.Requests}/${requestId}/actions`, patchDto).pipe(
      map((request) => {
        return {
          ...request,
          isCreateMode: getRequestCreateUpdateMode(request.requestTypeId) === ERequestCreateUpdate.create,
          mode: getRequestTypesMode(request.requestTypeId),
        };
      }),
    );
  }

  public remove(requestId: number) {
    return this.http.delete<void>(`${ApiPaths.Requests}/${requestId}`);
  }

  public getAttachments(requestId: number): Observable<AttachmentDto[]> {
    return this.http.get<AttachmentDto[]>(`${ApiPaths.Requests}/${requestId}/attachments`);
  }

  public getAttachmentById(requestId: number, attachmentId: number) {
    return this.http.get(`${ApiPaths.Requests}/${requestId}/attachments/${attachmentId}`, {
      responseType: 'blob',
    });
  }

  public getAllAttachmentById(requestId: number) {
    return this.http.get(`${ApiPaths.Requests}/${requestId}/attachments/batch`, {
      responseType: 'blob',
    });
  }

  public getAttachment(requestId: number, attachmentId: number) {
    return this.http.get(this.getAttachmentUrl(requestId, attachmentId), {
      responseType: 'blob',
    });
  }

  public getAttachmentUrl(requestId: number, attachmentId: number) {
    return `${ApiPaths.Requests}/${requestId}/attachments/${attachmentId}`;
  }

  public removeAttachment(requestId: number, attachmentId: number) {
    return this.http.delete(`${ApiPaths.Requests}/${requestId}/attachments/${attachmentId}`);
  }

  public uploadAttachments(requestId: number, files: File[]) {
    const formData: FormData = new FormData();
    files.forEach((file: File) => formData.append('files', file));

    return this.http.post(`${ApiPaths.Requests}/${requestId}/attachments`, formData);
  }

  public createRequestGoogleSheet(requestId: number) {
    return this.http.post<string>(`${ApiPaths.Requests}/${requestId}/sheets`, null);
  }

  public createGoogleSheet(filter: RequestsFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<string>(`${ApiPaths.Requests}/filter/sheets`, params);
  }

  public createExcelSheetAll(filter: RequestsFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Requests}/filter/excel`, params, { responseType: 'blob' });
  }

  public importRequestGoogleSheet(requestId: number, sheetId: string) {
    return this.http.put<RequestDto>(`${ApiPaths.Requests}/${requestId}/sheets/${sheetId}`, null).pipe(
      map((request) => {
        return {
          ...request,
          isCreateMode: getRequestCreateUpdateMode(request.requestTypeId) === ERequestCreateUpdate.create,
          mode: getRequestTypesMode(request.requestTypeId),
        };
      }),
    );
  }

  public createExcelSheet(requestId: number): Observable<Blob> {
    return this.http.post(`${ApiPaths.Requests}/${requestId}/excel`, null, { responseType: 'blob' });
  }

  public getRequestTypes(): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Requests}/requestTypes`);
  }

  public importExcelSheet(requestId: number, file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Requests}/${requestId}/excel`, data);
  }

  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Requests}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }
}
