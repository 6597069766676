import { TablePageFilter } from '../table-page-filter-base';
import { IRequestsFilterParams } from '@common/models/filters';
import { AttributesFilter, RangeDateDto } from '@common/dto';
import { SortOrder } from '@common/interfaces';
import { formatRangeDate } from '@shared/utils';

const DEFAULT_PARAMS: IRequestsFilterParams = {
  sortBy: 'created',
  sortOrder: SortOrder.Descending,
  withoutModerator: false,
};

export class RequestsFilterParams extends TablePageFilter<IRequestsFilterParams> {
  id: number;
  description: string;
  functionalTypeIds: number[];
  familyIds: number[];
  materialIds: number[];
  requestSolutionMaterialIds: number[];
  requestSolutionFamilyVersionIds: number[];
  requestStatuses: number[];
  initiatorIds: number[];
  requestTypes: number;
  moderatorIds: number[];
  approverIds: number[];
  developerIds: number[];
  verificationDate: RangeDateDto;
  toModeratorDate: RangeDateDto;
  moderatorDeveloperDate: RangeDateDto;
  moderatorApproverDate: RangeDateDto;
  developerDate: RangeDateDto;
  cancellationDate: RangeDateDto;
  approverDate: RangeDateDto;
  created: RangeDateDto;
  modified: RangeDateDto;
  planDate: RangeDateDto;
  userRelatedId: number;
  initiator: string;
  moderator: string;
  approver: string;
  developer: string;
  ignoreNotMine: boolean;
  ignoreFinished: boolean;
  withoutModerator: boolean;
  familyId: number;
  initiatorAttributes: AttributesFilter;
  moderatorAttributes: AttributesFilter;
  isDependsOnMe: boolean;
  disciplineIds: number[];
  withDisciplinesOnly: boolean;
  searchString: string;
  includeRequestSolutionMaterial?: boolean;
  includeRequestSolutionFamilyVersion?: boolean;

  constructor(filter?: IRequestsFilterParams) {
    super(filter, DEFAULT_PARAMS);
  }

  public toObject(): IRequestsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      description: this.description,
      functionalTypeIds: this.functionalTypeIds,
      familyIds: this.familyIds,
      materialIds: this.materialIds,
      requestSolutionMaterialIds: this.requestSolutionMaterialIds,
      requestSolutionFamilyVersionIds: this.requestSolutionFamilyVersionIds,
      requestStatuses: this.requestStatuses,
      initiatorIds: this.initiatorIds,
      requestTypes: this.requestTypes,
      moderatorIds: this.moderatorIds,
      approverIds: this.approverIds,
      developerIds: this.developerIds,
      verificationDate: formatRangeDate(this.verificationDate),
      toModeratorDate: formatRangeDate(this.toModeratorDate),
      moderatorDeveloperDate: formatRangeDate(this.moderatorDeveloperDate),
      moderatorApproverDate: formatRangeDate(this.moderatorApproverDate),
      developerDate: formatRangeDate(this.developerDate),
      cancellationDate: formatRangeDate(this.cancellationDate),
      isDependsOnMe: Boolean(this.isDependsOnMe),
      approverDate: formatRangeDate(this.approverDate),
      created: formatRangeDate(this.created),
      modified: formatRangeDate(this.modified),
      planDate: formatRangeDate(this.planDate),
      userRelatedId: this.userRelatedId,
      initiator: this.initiator,
      moderator: this.moderator,
      approver: this.approver,
      developer: this.developer,
      ignoreNotMine: this.ignoreNotMine,
      ignoreFinished: this.ignoreFinished,
      withoutModerator: this.withoutModerator,
      familyId: this.familyId,
      initiatorAttributes: this.initiatorAttributes,
      moderatorAttributes: this.moderatorAttributes,
      disciplineIds: this.disciplineIds,
      withDisciplinesOnly: this.withDisciplinesOnly,
      searchString: this.searchString,
      includeRequestSolutionMaterial: this.includeRequestSolutionMaterial,
      includeRequestSolutionFamilyVersion: this.includeRequestSolutionFamilyVersion,
    };
  }
}
