<fieldset>
  <span class="mr2">{{ 'HISTORY_MODULE.FROM' | translate }}</span>
  <pik-datepicker [formControl]="dateFrom" inverse [max]="today"></pik-datepicker>
  <span class="ml2 mr2">{{ 'HISTORY_MODULE.TO' | translate }}</span>
  <pik-datepicker [formControl]="dateTo" inverse [max]="today"></pik-datepicker>
</fieldset>

<pik-loader class="loader" *ngIf="pending; else content"></pik-loader>

<ng-template #content>
  <ng-container *ngIf="historyData?.length; else noHistory">
    <div class="history-item" *ngFor="let item of historyData">
      <div>
        <span class="flex">
          <svg-icon class="calendar mr1" src="./assets/icons/calendar.svg"></svg-icon>
          <pik-tag class="mb1" color="#f2f2f2" textColor="#000">
            {{ item.changeDate | historyDate }}
          </pik-tag>
        </span>
        <div class="change__time-array" *ngFor="let changeTime of item.changeTimes">
          <pik-tag class="change__time-item" color="#f2f2f2" textColor="#000">
            {{ changeTime.time }}
          </pik-tag>
          <div>
            <div *ngFor="let value of changeTime.values">
              <ng-container [ngSwitch]="value.action">
                <ng-container *ngSwitchCase="HistoryActions.add">
                  <a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.ADD' | translate }} <span class="field_name">{{ value.fieldName }}</span>
                  {{ 'HISTORY_MODULE.ACTIONS.WITH_VALUE' | translate }}
                  <app-parameters-view
                    [dataType]="value.fieldName === 'Допустимые значения' ? value.oldDataType : DataTypeCodeEnum.String"
                    [value]="value.newValue"
                  ></app-parameters-view>
                </ng-container>
                <ng-container *ngSwitchCase="HistoryActions.edit"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.EDIT' | translate }} <span class="field_name">{{ value.fieldName }}</span>
                  {{ 'HISTORY_MODULE.ACTIONS.FROM' | translate }}
                  <app-parameters-view
                    [dataType]="value.fieldName === 'Допустимые значения' ? value.oldDataType : DataTypeCodeEnum.String"
                    [value]="value.oldValue || ('HISTORY_MODULE.ACTIONS.NOT_SPECIFIED' | translate)"
                  ></app-parameters-view>
                  {{ 'HISTORY_MODULE.ACTIONS.TO' | translate }}
                  <app-parameters-view
                    [dataType]="value.fieldName === 'Допустимые значения' ? value.newDataType : DataTypeCodeEnum.String"
                    [value]="value.newValue || ('HISTORY_MODULE.ACTIONS.NOT_SPECIFIED' | translate)"
                  ></app-parameters-view
                ></ng-container>
                <ng-container *ngSwitchCase="HistoryActions.delete"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.DELETE' | translate }}
                  <span class="field_name">{{ value.fieldName }}</span>
                  {{ 'HISTORY_MODULE.ACTIONS.WITH_VALUE' | translate }}
                  <app-parameters-view
                    [dataType]="value.fieldName === 'Допустимые значения' ? value.oldDataType : DataTypeCodeEnum.String"
                    [value]="value.oldValue"
                  ></app-parameters-view
                ></ng-container>
                <ng-container *ngSwitchCase="HistoryActions.archive"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.ARCHIVE' | translate }}</ng-container
                >
                <ng-container *ngSwitchCase="HistoryActions.restore"
                  ><a target="_blank" [href]="'users/' + value.author.id">{{ value.author.name }}</a>
                  {{ 'HISTORY_MODULE.ACTIONS.RESTORE' | translate }}</ng-container
                >
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
</ng-template>

<ng-template #noHistory>
  <div class="mt2">{{ 'HISTORY_MODULE.NO_HISTORY' | translate }}</div>
</ng-template>
