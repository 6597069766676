import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPreparedHistoryObject } from '@common/dto/HistoryDto';
import { HistoryActions, HistoryType } from '@common/interfaces/history.interface';
import { HistoryApiService } from '@services/api/history-api.service';
import { dateToISOLikeButLocal } from '@shared/utils';
import { combineLatest, finalize, Subject, takeUntil } from 'rxjs';
import { DataTypeCodeEnum, DataTypeCodeEnumRu } from '@common/enums';
import { EHistoryFieldNames } from './types/history.types';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnDestroy {
  _destroy$ = new Subject();
  dateFrom = new FormControl(null);
  dateTo = new FormControl(null);
  historyData: IPreparedHistoryObject[] = null;
  pending = false;
  today = new Date();
  HistoryActions = HistoryActions;
  DataTypeCodeEnum = DataTypeCodeEnum;

  __data: { id: number; mode: HistoryType } = null;
  @Input() set data(value: { id: number; mode: HistoryType }) {
    this.__data = value;
    if (value) {
      this.dateFrom.setValue(new Date().setMonth(new Date().getMonth() - 1));
      this.dateTo.setValue(new Date());
    }
  }
  get data(): { id: number; mode: HistoryType } {
    return this.__data;
  }

  @Input() dataType: DataTypeCodeEnum = DataTypeCodeEnum.String;

  constructor(private historyApiService: HistoryApiService) {
    combineLatest([this.dateFrom.valueChanges, this.dateTo.valueChanges])
      .pipe(takeUntil(this._destroy$))
      .subscribe((_) => {
        this.setData(this.data.id);
      });
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  setData(id: number): void {
    if (!id) return;
    this.pending = true;
    this.historyApiService
      .getHistoryById(id, dateToISOLikeButLocal(this.dateFrom.value), dateToISOLikeButLocal(this.dateTo.value))
      .pipe(
        finalize(() => {
          this.pending = false;
        }),
      )
      .subscribe((resp) => {
        this.historyData = [];

        resp.forEach((item) => {
          this.historyData.push({
            changeDate: item.changeDate,
            changeTimes: item.changes.map((change) => {
              const newDataType =
                DataTypeCodeEnumRu[
                  change.values.find((item) => item.fieldName === EHistoryFieldNames.dataType)?.newValue ||
                    this.dataType ||
                    DataTypeCodeEnumRu.Строка
                ];
              const oldDataType =
                DataTypeCodeEnumRu[
                  change.values.find((item) => item.fieldName === EHistoryFieldNames.dataType)?.oldValue ||
                    this.dataType ||
                    DataTypeCodeEnumRu.Строка
                ];
              return {
                time: change.time,
                values: change.values.length
                  ? change.values.map((value) => ({
                      action: change.action,
                      author: change.author,
                      oldDataType,
                      newDataType,
                      ...value,
                    }))
                  : [{ action: change.action, author: change.author }],
              };
            }),
            //TODO если потребуется фильтрвать с пустыми занчениями
            // .filter((change) => change.values.length),
          });
        });
      });
  }
}
