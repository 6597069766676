export const environment = {
  stand: 'development',
  lastBuildTime: '10-10-2024 15:57:57',
  version: '24.5.2',
  production: false,
  traceRoute: true,
  hrmImageEndpoint: 'https://hrm.bimteam.ru/api/v2',
  fmEndpoint: 'https://fm2-api.bimteam.ru/v1',
  locale: 'ru-RU',
  metrikaWebvisor: false,
  accessLink: 'https://jira.pik.ru/servicedesk/customer/portal/2/create/641',
  isGoogleIntegrationEnabled: true,
  supportLink: 'https://jira.pik.ru/servicedesk/customer/portal/2/create/129',

  auth: {
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    redirectUri: window.location.origin + '/implicit-redirect',
    requireHttps: false,
    showDebugInformation: true,
    disableAtHashCheck: true,
    sendAccessToken: true,
    issuer: 'https://test.auth.pik-digital.ru',
    clientId: 'fm_spa',
    scope: 'fm_api allow_external',
    responseType: 'id_token token',
    allowedUrls: ['https://fm2-api.bimteam.ru'],
  },
};
