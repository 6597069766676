<span
  (click)="parameterViewService.clickedValue = rowValues[indx]"
  *ngFor="let item of values | slice: 0:(isShowAllCount || !maxCount ? values.length : maxCount); let indx = index"
>
  <ng-container [ngSwitch]="item.type">
    <span [ngStyle]="{ color: textColor }">
      <ng-container *ngSwitchCase="ParameterValueType.AnyString">
        <i> <{{ 'PARAMETERS_VIEW_COMPONENT.ANY_STRING' | translate }}> </i>
      </ng-container>
      <ng-container *ngSwitchCase="ParameterValueType.AnyNumber">
        <i> <{{ 'PARAMETERS_VIEW_COMPONENT.ANY_NUMBER' | translate }}> </i>
      </ng-container>
    </span>

    <ng-container *ngSwitchCase="ParameterValueType.StringWithSpecSymbols">
      <pik-tag [color]="ThemeColors.Gray4" [textColor]="textColor" large>
        <span [innerHTML]="item.value | stringWithSpecialSymbols"></span>
      </pik-tag>
    </ng-container>

    <ng-container *ngSwitchCase="ParameterValueType.Boolean">
      <app-parameters-boolean-view [textColor]="textColor" [value]="item.value"></app-parameters-boolean-view>
    </ng-container>

    <ng-container *ngSwitchCase="ParameterValueType.Interval">
      <pik-tag [color]="ThemeColors.Gray4" [textColor]="textColor" large>
        {{ item.intervalValue[0] }}
        <ng-container *ngIf="item.intervalValue[1]">
          {{ 'PARAMETERS_VIEW_COMPONENT.WITH_STEP' | translate }}
          {{ item.intervalValue[1] }}
        </ng-container>
      </pik-tag>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="item.value !== '*'; else anyValue">
        <pik-tag
          [class.pointer]="disableMaterialLink ? null : isMaterialParam"
          (click)="navigateToTab('symbolMaterials')"
          [color]="isMaterialParam ? ThemeColors.Material : ThemeColors.Gray4"
          [textColor]="textColor"
          large
        >
          <span>{{ item.value }}</span>
        </pik-tag>
      </ng-container>
      <ng-template #anyValue>
        <ng-container *ngIf="_dataType !== DataTypeCodeEnum.Material && _dataType !== DataTypeCodeEnum.Element">
          <i> <{{ 'PARAMETERS_VIEW_COMPONENT.ANY_VALUE' | translate }}> </i>
        </ng-container>
        <ng-container *ngIf="_dataType === DataTypeCodeEnum.Material">
          <i> <{{ 'PARAMETERS_VIEW_COMPONENT.ANY_MATERIAL' | translate }}> </i>
        </ng-container>
        <ng-container *ngIf="_dataType === DataTypeCodeEnum.Element">
          <i> <{{ 'PARAMETERS_VIEW_COMPONENT.ANY_ELEMENT' | translate }}> </i>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</span>

<pik-tag
  class="toggle-tag"
  *ngIf="values.length > maxCount"
  [color]="'transparent'"
  [textColor]="ThemeColors.DarkBlue"
  (click)="toggle()"
  large
>
  {{
    isShowAllCount
      ? ('COMMON.HIDE' | translate)
      : ('COMMON_PHRASE.AND_MORE' | translate: { number: values.length - maxCount })
  }}
</pik-tag>
